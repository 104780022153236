<template>
  <a-select style="width: 410px;height: 38px;" show-search :value="getValueSting" placeholder="请输入详细地址"
    :default-active-first-option="false" :show-arrow="false" :filter-option="false" :disabled="disabled"
    :not-found-content="null" @search="searchSuggestions" @change="onSuggestionChoose">
    <a-select-option v-for="(item, index) in suggestionData" :key="item.index" :title="item.name" :value="item.name">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
  name: "MapPicker",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    height: {  // 地图的高度
      type: String,
      default: '450px'
    },
    center: Array,  // 地图默认中心点
    zoom: {  // 地图初始缩放级别
      type: Number,
      default: 11
    },
    chooseZoom: {  // 地图选中后缩放级别
      type: Number,
      default: 17
    },
    poiSize: {  // poi检索每页数量
      type: Number,
      default: 30
    },
    poiType: {  // poi检索兴趣点类别
      type: String,
      default: ''
    },
    poiKeywords: {  // poi检索关键字
      type: String,
      default: ''
    },
    poiRadius: {  // poi检索半径
      type: Number,
      default: 1000
    },
    needCity: Boolean,  // 是否返回行政区
    forceChoose: {  // 是否强制选择
      type: Boolean,
      default: true
    },
    // suggestionCity: {  // 搜索建议的城市范围
    //   type: String,
    //   default: '全国'
    // },
    searchType: {  // 地点检索类型,0:POI检索,1:关键字检索
      type: Number,
      default: 0
    },
    searchPlaceholder: {
      type: String,
      default: '输入关键字搜索'
    },
    markerSrc: {  // marker图标地址
      type: String,
      default: 'https://3gimg.qq.com/lightmap/components/locationPicker2/image/marker.png'
    },
    mapKey: {  // 地图默认的key
      type: String,
      default: '006d995d433058322319fa797f2876f5'
    },
    mapVersion: {  // 地图默认版本号
      type: String,
      default: '2.0'
    },
    /* 弹窗参数 */
    title: {  // 弹窗的标题
      type: String,
      default: '选择位置'
    },
    width: {  // 弹窗的宽度
      type: String,
      default: '780px'
    },
    customClass: String,  // Dialog 的自定义类名
    closeOnClickModal: {  // 是否可以通过点击 modal 关闭 Dialog
      type: Boolean,
      default: false
    },
    closeOnPressEscape: {  // 是否可以通过按下 ESC 关闭 Dialog
      type: Boolean,
      default: true
    },
    showClose: {  // 是否显示关闭按钮
      type: Boolean,
      default: true
    },
    lockScroll: {  // 是否在 Dialog 出现时将 body 滚动锁定
      type: Boolean,
      default: true
    },
    appendToBody: Boolean,  // Dialog 自身是否插入至 body 元素上
    modalAppendToBody: {  // 遮罩层是否插入至 body 元素上
      type: Boolean,
      default: true
    },
    modal: {  // 是否需要遮罩层
      type: Boolean,
      default: true
    },
    top: String,  // Dialog CSS 中的 margin-top 值
    destroyOnClose: {  // 关闭时销毁 Dialog 中的元素
      type: Boolean,
      default: true
    },
    value: [String, Number],
    show: Boolean  // 是否显示
  },
  data() {
    return {
      showMap: false,  // 是否显示地图
      map: null,  // 地图实例
      centerMarker: null,  // 地图中心标记点
      placeSearch: null,  // poi检索实例
      autoComplete: null,  // 输入建议实例
      poiData: [],  // poi检索的数据
      chooseIndex: null,  // 选中的数据
      isSelMove: false,  // 是否是点击poi列表移动地图
      showIconAnim: false,  // 图标是否显示跳动动画
      suggestionKeyWord: '',  // 搜索输入内容
      lastSuggestion: '',  // 上次搜索输入内容
      suggestionData: [],  // 搜索建议列表
      chooseSuggestion: null,  // 选中的建议
      loading: true,  // 初始化loading
      suggestionCity: '全国',
      poiLoading: false,  // poi检索loading
      doneLoading: false  // 确定按钮loading
    }
  },
  computed: {
    getValueSting() {
      return this.value ? this.value : undefined
    },
  },
  methods: {
    /* 渲染地图 */
    render(cityName = '全国') {
      AMapLoader.load({
        'key': this.mapKey,
        'version': this.mapVersion,
        'plugins': ['AMap.PlaceSearch', 'AMap.AutoComplete']
      }).then((AMap) => {
        // 获取poi检索实例
        this.placeSearch = new AMap.PlaceSearch({
          type: this.poiType, pageSize: this.poiSize, pageIndex: 1, city: cityName
        });
        // 获取输入建议实例
        this.autoComplete = new AMap.AutoComplete({ city: cityName });
      }).catch(e => {
        this.loading = false;
        console.error(e);
      });
    },
    /* poi列表选中 */
    choose(index) {
      this.chooseIndex = index;
      this.isSelMove = true;
      this.showIconAnim = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.showIconAnim = true;
        }, 0);
      });
      let point = this.poiData[index].location;
      this.map.setZoomAndCenter(this.chooseZoom, [point.lng, point.lat]);
      // 添加标记点
      if (this.searchType !== 0) {  // 非poi检索类型给地图添加中心标记点
        this.centerMarker.setPosition([point.lng, point.lat]);
        this.map.add(this.centerMarker);
      }
    },
    /* 搜索建议 */
    searchSuggestions(keywords) {
      if (!this.autoComplete || !keywords || this.lastSuggestion === keywords) return;
      if (this.searchType !== 0) this.poiLoading = true;
      console.log(keywords)
      this.lastSuggestion = keywords;
      this.autoComplete.search(keywords, (status, result) => {
        console.warn(status, result)
        if (this.searchType === 0) {  // POI检索模式
          if (result.tips) {
            result.tips.forEach(d => {
              d.value = '';
              if (Array.isArray(d.address)) d.address = d.address[0];
            });
            this.suggestionData = result.tips;
          } else {
            this.suggestionData = [];
          }
          // callback(this.suggestionData);
        } else {  // 关键字检索模式
          if (result.tips) {
            result.tips.forEach(d => {
              d.value = '';
              if (Array.isArray(d.address)) d.address = d.address[0];
            });
            this.poiData = result.tips;
          } else {
            this.poiData = [];
          }
          this.chooseIndex = null;
          this.map.remove(this.centerMarker);
          this.poiLoading = false;
          // callback([]);
        }
      });
    },
    /* 搜索建议选中 */
    onSuggestionChoose(value, item) {
      console.log(value, item)
      this.suggestionKeyWord = ''
      this.chooseSuggestion = this.suggestionData.find(i => i.name === value);
      console.log(this.chooseSuggestion)
      if (this.chooseSuggestion) {
        this.$emit('input', value)
        this.$emit('change', value)
        if (this.chooseSuggestion.location) {
          const { lng, lat } = this.chooseSuggestion.location
          console.log(lng, lat)
          this.$emit('done', lng + ',' + lat)
        } else {
          this.$emit('done', undefined)
        }
      } else {
        this.$emit('input', undefined)
        this.$emit('change', undefined)
        this.$emit('done', undefined)
      }
    },
    onOpen(cityName = null) {
      console.log('cityName', cityName)
      this.suggestionCity = cityName || '全国'
      this.showMap = true;
      this.render(cityName || '全国');  // 渲染地图
    },
  },
  model: {
      prop: 'value',
      event: 'change',
    },
}
</script>

<style lang="less" scoped>
::v-deep .ant-select-selection--single {
  height: 38px;
}
::v-deep .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>